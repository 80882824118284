import { ConnectivityResponse, UsabilityResponse } from "@prismadelabs/prismaid";
import { AnimatePresence, motion } from "framer-motion";
import { useMotionTimeline } from "motion-hooks";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Sound from "../../../components/Sound";
import LoadedImage from "../../../components/UI/images/LoadedImage";
import Spinner from "../../../components/UI/Spinner";
import { getScaleFactor, getScaleFactorFromLocalStorage } from "../../../helper/scale";
import useTimeout from "../../../hooks/useTimeout";
import SDKSingleton from "../../../SDK";
import { useAppDispatch, useAppSelector } from "../../../state/hooks";
import {
  addSwipeMessage,
  requestFirstTouchMessage,
  setConnectivityStatus,
  setIsActive,
  setProgress,
  setScaleFactor,
} from "../../../state/slices/swipe";
import { RootState } from "../../../state/store";
import BrowserNotSupportedModal from "./modals/BrowserNotSupportedModal";
import DeviceNotSupportedModal from "./modals/DeviceNotSupportedModal";
import GloveModeModal from "./modals/sensitivity/GloveModeModal";
import PointerSpeedModal from "./modals/sensitivity/PointerSpeedModal";
import TouchSensitivityModal from "./modals/sensitivity/TouchSensitivityModal";
import ShouldAddToHomeScreenModal from "./modals/ShouldAddToHomeScreenModal";

import placement from "../../../assets/img/05_scan/scan_card-neutral.png";
import target from "../../../assets/img/05_scan/scan_swipe-dot.png";
// TODO get good quality asset in correct size
import swipePath from "../../../assets/img/05_scan/scan_swipe.png";

import hand from "../../../assets/img/05_scan/scan_hand.png";
import thumb from "../../../assets/img/05_scan/scan_thumb.png";
import touch from "../../../assets/img/05_scan/scan_touch.png";

import troll_game from "../../../assets/img/04_game/level_king-trollex.png";
import troll_collection from "../../../assets/img/04_game/level_tiny-diamond.png";

import error from "../../../assets/sounds/error.mp3";
import success from "../../../assets/sounds/success.mp3";

type Props = {
  origin: string;
  isGameWithSound?: boolean;
};

const messages = [
  { key: "card_unstable", value: "Please swipe again and hold the card firm." },
  { key: "invalid_signal", value: "Please swipe again." },
  { key: "swipe_faster", value: "Please swipe over the card more quickly." },
  { key: "swipe_slower", value: "Please swipe over the card more slowly." },
  { key: "swipe_without_card", value: "Please swipe over the card." },
];

// component
const FunctionalSwipeField = (props: Props) => {
  const dispatch = useAppDispatch();
  let navigate = useNavigate();

  const scaleFactor = useAppSelector((state: RootState) => state.swipe.scaleFactor);
  const isActive = useAppSelector((state: RootState) => state.swipe.isActive);

  const [showModal_TouchSensitivity, setShowModal_TouchSensitivity] = useState(false);
  const [showModal_GloveMode, setShowModal_GloveMode] = useState(false);
  const [showModal_pointerspeed, setShowModal_pointerspeed] = useState(false);
  const [showModal_ShouldAddToHomeScreen, setShowModal_ShouldAddToHomeScreen] = useState(false);
  const [showModal_browserNotSupported, setShowModal_browserNotSupported] = useState(false);
  const [showModal_deviceNotSupported, setShowModal_deviceNotSupported] = useState(false);

  const successSound = useRef(new Sound(success));
  const errorSound = useRef(new Sound(error));

  const [errorCount, setErrorCount] = useState(0);

  const [shouldPlaySwipeAnimation, setShouldPlaySwipeAnimation] = useState(true);

  const [flashRedAlert, setFlashRedAlert] = useState(false);
  const [flashGreenAlert, setFlashGreenAlert] = useState(false);

  const [sdk] = useState(SDKSingleton.getInstance().sdk);

  const screenRef = useRef<HTMLDivElement>(null);

  // configure sdk
  useEffect(() => {
    // FIXME remove!
    // iPhone 12Pro
    // var scale = getScaleFactor(460, 3);
    // dispatch(setScaleFactor(scale));

    sdk.resume();

    let initialisationSubject = sdk.getInitialisationSubject().subscribe((response) => {
      console.log("*) initialisationResponse", response);

      if (response.ppi) {
        var scale = getScaleFactor(response.ppi, response.devicePixelRatio);

        if (!Number.isNaN(scale)) {
          dispatch(setScaleFactor(scale));
        }
      } else {
        setShowModal_deviceNotSupported(true);
        return;
      }

      if (response.deviceSupport?.requirements?.includes("touchsensitivity")) {
        setShowModal_TouchSensitivity(true);
        return;
      }

      if (response.deviceSupport?.requirements?.includes("glovemode")) {
        setShowModal_GloveMode(true);
        return;
      }

      if (response.deviceSupport?.requirements?.includes("pointerspeed")) {
        setShowModal_pointerspeed(true);
        return;
      }
    });

    const usabilitySubject = sdk.getUsabilitySubject().subscribe((response: UsabilityResponse) => {
      console.log("*) usabilityResponse", response);
      if (response.event === "device_not_supported") {
        setShowModal_deviceNotSupported(true);
        return;
      }
      if (response.event === "browser_not_supported") {
        setShowModal_browserNotSupported(true);
        return;
      }
      if (response.event === "display_too_small_displacement") {
        setShowModal_deviceNotSupported(true);
        return;
      }
      if (response.event === "display_small_should_add_to_home") {
        setShowModal_ShouldAddToHomeScreen(true);
        return;
      }
    });

    const detectionSuccessSubject = sdk.getDetectionSuccessSubject().subscribe((response) => {
      console.log("*) detection success:", response.description());

      handleSwipeSuccess(response.codeId);
    });

    const detectionErrorSubject = sdk.getDetectionErrorSubject().subscribe((response) => {
      console.log("*) detection error:", response.description());
      response.hints.forEach((hint) => {
        console.log("*) hint:", hint.description());
      });

      let hintCode = "";
      // filter for handled error codes
      let codes = response.hints.filter((hint) => {
        return (
          hint.code === "card_unstable" ||
          hint.code === "invalid_signal" ||
          hint.code === "swipe_faster" ||
          hint.code === "swipe_slower" ||
          hint.code === "swipe_without_card"
        );
      });

      if (codes.length > 0) {
        hintCode = codes[0].code;
      }

      switch (hintCode) {
        case "card_unstable":
        case "invalid_signal":
        case "swipe_faster":
        case "swipe_slower":
        case "swipe_without_card":
          dispatch(
            addSwipeMessage({
              title: "Please repeat",
              message: messages[messages.findIndex((item) => item.key === hintCode)]?.value,
            })
          );
          break;
        default:
          // unknown code or ""
          dispatch(
            addSwipeMessage({
              title: "Please repeat",
              message: "Please swipe again.",
            })
          );
          break;
      }

      clickErrorButton();
    });

    const interactionSubject = sdk.getInteractionSubject().subscribe((response) => {
      console.log("*) interaction event:", response.event, response.activeSignals);

      switch (response.event) {
        case "started":
          dispatch(setIsActive(true));
          dispatch(requestFirstTouchMessage());

          let btnAll = document.getElementById("stopAnimationsButton") as HTMLButtonElement;
          btnAll.click();
          break;
        case "changed":
          break;
        case "complete":
          let btnRestart = document.getElementById("restartAnimationsButton") as HTMLButtonElement;
          btnRestart.click();
          dispatch(setIsActive(false));
          dispatch(setProgress(0));
          break;
        default:
          break;
      }
    });

    const progressSubject = sdk.getProgressSubject().subscribe((response) => {
      console.log("*) progress:", response.progress);
      dispatch(setProgress(response.progress));
    });

    const connectivitySubject = sdk.getConnectivitySubject().subscribe((response: ConnectivityResponse) => {
      console.log("*) connectivity response:", response.status);

      if (response.status === null) return;

      dispatch(setConnectivityStatus(response.status));
    });

    if (screenRef.current) {
      console.warn("is attached?", sdk.isElementAttached(screenRef.current));
      if (!sdk.isElementAttached(screenRef.current)) {
        sdk.attachToElement(screenRef.current);
        sdk.resetManual();
      }
      console.warn("is now attached?", sdk.isElementAttached(screenRef.current));
    }

    return () => {
      initialisationSubject.unsubscribe();
      usabilitySubject.unsubscribe();
      progressSubject.unsubscribe();
      connectivitySubject.unsubscribe();
      detectionSuccessSubject.unsubscribe();
      detectionErrorSubject.unsubscribe();
      interactionSubject.unsubscribe();

      if (screenRef.current) {
        console.warn("cleanup - is attached?", sdk.isElementAttached(screenRef.current));
        sdk.detachFromElement(screenRef.current);
        console.warn("cleanup - is now attached?", sdk.isElementAttached(screenRef.current));
      }

      sdk.resetManual();

      dispatch(setIsActive(false));
      dispatch(setProgress(0));
    };
  }, []);

  useTimeout(() => {
    if (!scaleFactor) {
      let storageFactor = getScaleFactorFromLocalStorage();
      if (storageFactor) {
        dispatch(setScaleFactor(storageFactor));
      }
    }
  }, 2000);

  useTimeout(
    () => {
      playSwipeAnimation();
    },
    shouldPlaySwipeAnimation ? 3000 : undefined
  );

  const { play: playSwipeAnimation, timelineInstance: swipeAnimation } = useMotionTimeline(
    // NOTE: always use units!
    // chrome will make problems, if '%' or 'px' are omitted
    [
      ["#thumb", { opacity: 1 }, { duration: 0.2 }],
      ["#thumbShell", { left: ["-50%", 0], top: ["30%", 0], scale: [1.3, 1] }, { duration: 2, at: "<" }],

      ["#hand", { opacity: 1 }, { duration: 0.2 }],
      ["#handShell", { left: ["100%", 0], top: ["-30%", 0], scale: [1.3, 1] }, { duration: 2, at: "<" }],

      ["#handShell", { top: [0, `${-1050 * scaleFactor}px`] }, { duration: 2, at: "+0.5" }],
      ["#swipePathShell", { height: ["30px", `${1100 * scaleFactor}px`] }, { duration: 2, at: "<" }],

      ["#swipePathShell", { height: "30px" }, { duration: 0.2, at: "+0.5" }],
      ["#handShell", { left: [null, "100%"], top: [null, "-30%"] }, { duration: 2, at: "<" }],
      ["#thumbShell", { left: [null, "-100%"], top: [null, "30%"] }, { duration: 2, at: "<" }],
    ],
    { repeat: 2 }
  );

  const stopAnimations = () => {
    setShouldPlaySwipeAnimation(false);
    swipeAnimation?.finish();
  };

  const restartAnimations = () => {
    setShouldPlaySwipeAnimation(true);
  };

  const handleSwipeSuccess = (codeId: string) => {
    sdk.pause();
    let btnAll = document.getElementById("stopAnimationsButton") as HTMLButtonElement;
    btnAll.click();
    successSound.current.play();
    showGreenAlert();

    switch (props.origin) {
      case "/collection":
        setTimeout(() => {
          navigate("/collection", { replace: true, state: { newTroll: codeId } });
        }, 1500);
        break;
      case "/levels":
        setTimeout(() => {
          navigate(props.isGameWithSound ? "/success1" : "/success2", { replace: true });
        }, 1500);
        break;

      default:
        setTimeout(() => {
          navigate("/", { replace: true });
        }, 1500);
        break;
    }

    setTimeout(() => {
      dispatch(setProgress(0));
    }, 3000);
  };

  // FIXME selector is not updating when called from subscription
  // works fine when using with button onClick
  // temporary workaround: use hidden button
  // will be fixed with useEvent in a later react version
  const clickErrorButton = () => {
    let btn = document.getElementById("errorButton") as HTMLButtonElement;
    btn.click();
  };

  const handleSwipeError = () => {
    errorSound.current.sound.currentTime = 0;

    if (errorCount >= 4) {
      errorSound.current.play();
      switch (props.origin) {
        case "/collection":
          navigate("/notroll", { replace: true });
          break;
        case "/levels":
          navigate("/nopower", { replace: true });
          break;
        default:
          navigate("/", { replace: true });
          break;
      }

      setTimeout(() => {
        dispatch(setProgress(0));
      }, 1000);
    } else {
      setErrorCount(errorCount + 1);
      showRedAlert();
    }
  };

  const showRedAlert = () => {
    setFlashRedAlert(true);
  };
  const showGreenAlert = () => {
    setFlashGreenAlert(true);
  };

  const closeTouchModalAndRestartAnimation = () => {
    setShowModal_TouchSensitivity(false);
    setShowModal_pointerspeed(false);
    setShowModal_GloveMode(false);

    setShouldPlaySwipeAnimation(false);
    restartAnimations();
  };

  return (
    <>
      <div ref={screenRef} id="swipeScreen" className="absolute top-0 left-0 w-screen h-full overflow-hidden">
        {!scaleFactor && !(scaleFactor > 0) ? (
          <>
            <div className="absolute inset-0 flex items-center justify-center text-white">
              <Spinner />
            </div>
          </>
        ) : (
          <>
            <LoadedImage
              src={placement}
              id="placement"
              alt="placement"
              horizontalAlign="left"
              verticalAlign="bottom"
              horizontalOffset={40}
              verticalOffset={-60}
              scaleFactor={scaleFactor}
            />

            {props.origin === "/collection" && (
              <img
                alt=""
                src={troll_collection}
                className="absolute bottom-0 right-0 translate-x-[40%] translate-y-[28%] pointer-events-none"
              />
            )}
            {props.origin === "/levels" && (
              <img alt="" src={troll_game} className="absolute bottom-0 left-0 translate-x-[-47%] translate-y-[40%] pointer-events-none" />
            )}

            <motion.div
              variants={{
                hidden: {
                  opacity: 0,
                  transition: { duration: 0.3 },
                },
                visible: {
                  opacity: 1,
                  transition: { duration: 0.3 },
                },
              }}
              initial="hidden"
              animate={isActive ? "visible" : "hidden"}
            >
              <LoadedImage
                src={target}
                id="target"
                alt="target"
                horizontalAlign="left"
                verticalAlign="bottom"
                horizontalOffset={477}
                verticalOffset={-1150}
                scaleFactor={scaleFactor}
              />
            </motion.div>

            <motion.div animate={{ opacity: [0.6, 1, 0.6], transition: { repeat: Infinity, duration: 2 } }}>
              <LoadedImage
                src={touch}
                id="touch"
                alt="touchpoint"
                horizontalAlign="left"
                verticalAlign="bottom"
                horizontalOffset={-50}
                verticalOffset={-450}
                scaleFactor={scaleFactor}
              />
            </motion.div>

            <motion.div variants={{ active: {}, inactive: {} }} animate={isActive ? "active" : "inactive"}>
              <div
                className="absolute bottom-0 left-0 w-screen overflow-hidden pointer-events-none touch-none"
                id="swipePathShell"
                style={{ height: "0px" }}
              >
                <LoadedImage
                  src={swipePath}
                  id="swipePath"
                  alt="swipePath"
                  horizontalAlign="left"
                  verticalAlign="bottom"
                  horizontalOffset={483}
                  verticalOffset={-88}
                  scaleFactor={scaleFactor}
                />
              </div>
            </motion.div>

            <div className="absolute w-screen h-full pointer-events-none touch-none" id="thumbShell" style={{ left: 0, top: 0 }}>
              <LoadedImage
                src={thumb}
                id="thumb"
                alt="thumb"
                horizontalAlign="left"
                verticalAlign="bottom"
                horizontalOffset={-390}
                verticalOffset={150}
                scaleFactor={scaleFactor}
                className="opacity-0"
              />
            </div>

            <div className="absolute w-screen h-full pointer-events-none touch-none" id="handShell" style={{ left: 0, top: 0 }}>
              <LoadedImage
                src={hand}
                id="hand"
                alt="hand"
                horizontalAlign="left"
                verticalAlign="bottom"
                horizontalOffset={460}
                verticalOffset={1350}
                scaleFactor={scaleFactor}
                className="opacity-0"
              />
            </div>

            {flashRedAlert && (
              <AnimatePresence>
                <motion.div
                  className="absolute inset-0 h-full bg-standard-red/90 touch-none"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: [100, 0] }}
                  transition={{
                    duration: 0.2,
                    ease: "linear",
                    repeat: 1,
                    repeatDelay: 0.2,
                  }}
                  onAnimationComplete={() => {
                    setFlashRedAlert(false);
                  }}
                />
              </AnimatePresence>
            )}
            {flashGreenAlert && (
              <AnimatePresence>
                <motion.div
                  className="absolute inset-0 h-full bg-standard-green/90 touch-none"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: [100, 0] }}
                  transition={{
                    duration: 0.2,
                    ease: "linear",
                    repeat: 1,
                    repeatDelay: 0.2,
                  }}
                  onAnimationComplete={() => {
                    setFlashGreenAlert(false);
                  }}
                />
              </AnimatePresence>
            )}
          </>
        )}
      </div>

      <TouchSensitivityModal isOpen={showModal_TouchSensitivity} setIsOpen={closeTouchModalAndRestartAnimation} />
      <GloveModeModal isOpen={showModal_GloveMode} setIsOpen={closeTouchModalAndRestartAnimation} />
      <PointerSpeedModal isOpen={showModal_pointerspeed} setIsOpen={closeTouchModalAndRestartAnimation} />

      <ShouldAddToHomeScreenModal isOpen={showModal_ShouldAddToHomeScreen} setIsOpen={setShowModal_ShouldAddToHomeScreen} />
      <BrowserNotSupportedModal isOpen={showModal_browserNotSupported} setIsOpen={setShowModal_browserNotSupported} />
      <DeviceNotSupportedModal isOpen={showModal_deviceNotSupported} setIsOpen={setShowModal_deviceNotSupported} />

      <div className="absolute z-10 space-x-2 space-y-2 bottom-2">
        {props.origin === "/collection" && (
          <>
            <button onClick={() => handleSwipeSuccess("code37")} className="p-2 ml-2 bg-white rounded-xl">
              Cooper
            </button>
            <br />
            <button onClick={() => handleSwipeSuccess("code31")} className="p-2 bg-white rounded-xl">
              Branch
            </button>
            <br />
            <button onClick={() => handleSwipeSuccess("code14")} className="p-2 bg-white rounded-xl">
              Poppy
            </button>
          </>
        )}
        {props.origin === "/levels" && (
          <button onClick={() => handleSwipeSuccess("code37")} className="p-2 ml-2 bg-white rounded-xl">
            Success
          </button>
        )}

        <button onClick={() => handleSwipeError()} id="errorButton" className="hidden">
          handleSwipeError
        </button>
        <button
          onClick={() => {
            stopAnimations();
          }}
          id="stopAnimationsButton"
          className="hidden"
        >
          stopSwipeAnimation
        </button>
        <button
          onClick={() => {
            restartAnimations();
          }}
          id="restartAnimationsButton"
          className="hidden"
        >
          restartSwipeAnimation
        </button>
      </div>
    </>
  );
};

export default FunctionalSwipeField;
